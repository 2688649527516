@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&display=swap");

body {
  margin: 0;
  font-family: "Inter Tight", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #000; */
}

.wrapper {
  background-image: url("./assets/images/void.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.error-wrapper {
  background-image: url("./assets/images/void.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background: #000;
  height: 100vh;
}

input,
select {
  outline: none !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.no-scrollbar {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.ant-modal-content {
  background-color: #0d0d0d !important;
}

.ant-modal-close {
  color: #fff !important;
}

.loader {
  display: flex;
  margin: 0 auto;
  width: 3.5em;
  height: 3.5em;
  border: 3px solid transparent;
  border-top-color: #ff5101a2;
  border-bottom-color: #ff5101a2;
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
}

.loader:before {
  content: "";
  display: block;
  margin: auto;
  width: 0.75em;
  height: 0.75em;
  border: 3px solid #ff5001;
  border-radius: 50%;
  animation: pulse 1s alternate ease-in-out infinite;
}

.ant-modal-content {
  background-color: #fff !important;
}
